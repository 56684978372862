.subcaterogy_menu {
    display: flex;
    flex-direction: column;
    margin: 1vh 1vh;
  
    .category_title_con {
      display: flex;
      align-items: center;
      cursor: pointer;
  
      &:hover {
        .category_title {
          transition: 0.3s;
          color: #000000c1;
        }
      }
  
      .dropdown_arrow_img {
        width: 2.2vh;
        transition: 0.3s;
  
        &.active {
          transform: rotate(180deg);
        }
      }
    }
  
    .drop_down_con {
      display: flex;
      padding: 1vh;
      gap: 0.1vh;
      max-width: 100%;
      overflow-x: auto;
  
      .category_column {
        display: flex;
        flex-direction: column;
        background-color: #e0e0e0;
        padding: 10px;
        min-width: 200px;
      }
  
      .category_li {
        cursor: pointer;
        padding: 5px;

        &:hover, &.active {
          background-color: #555;
          color: white;
        }
      }
    }
  }
  