@media (max-width: 4320px) {
  #desktop {
    display: none;
    }
    #slider input {
      display: none;
    }
    
    #slider { /*положение слайдера*/
    position: relative;
    text-align: center;
    margin: 0 auto;
    }
    
    #slides article { /*все изображения справа друг от доруга*/
    width: 20%;
    float: left;
    }
    
    #slides .image { /*устанавливает общий размер блока с изображениями*/
    width: 500%;
    line-height: 0;
    }
    
    #overflow { /*сркывает все, что находится за пределами этого блока*/
    width: 100%;
    overflow: hidden;
    }
    
    article img { /*размер изображений слайдера*/
    width: 60%;
    }
    
    #desktop:checked ~ #slider { /*размер всего слайдера*/
    max-width: 1440px; /*максимальнная длинна*/
    background-color: white;
    }
    
    /*настройка переключения и положения для левой стрелки*/
    /*если свич1-3 активны, то идет обращение к лейблу из блока с id контролс*/
    #switch1:checked ~ #controls label:nth-child(3),
    #switch2:checked ~ #controls label:nth-child(1),
    #switch3:checked ~ #controls label:nth-child(2) {
    background: url("../../../img/prev.png") no-repeat; /*заливка фона картинкой без повторений*/
    float: left;
    margin: 70px 0 0 20px; /*сдвиг влево*/
    display: block;
    height: 68px;
    width: 68px;
    }
    
    /*настройка переключения и положения для правой стрелки*/
    #switch1:checked ~ #controls label:nth-child(2),
    #switch2:checked ~ #controls label:nth-child(3),
    #switch3:checked ~ #controls label:nth-child(1) {
    background: url("../../../img/next.png") no-repeat; /*заливка фона картинкой без повторений*/
    float: right;
    margin-top: 70px;
    display: block;
    height: 68px;
    width: 68px;
    }
    
    .container3 label, a { /*при наведении на стрелки или переключатели - курсор изменится*/
    cursor: pointer;
    }
    
    .all input { /*скрывает стандартные инпуты (чекбоксы) на странице*/
    display: none;
    }
    
    /*позиция изображения при активации переключателя*/
    #switch1:checked ~ #slides .image {
    margin-left: 0;
    }
    
    #switch2:checked ~ #slides .image {
    margin-left: -100%;
    }
    
    #switch3:checked ~ #slides .image {
    margin-left: -200%;
    }
    
    #controls { /*положение блока всех управляющих элементов*/
    margin: -25% 0 0 0;
    width: 100%;
    height: 50px;
    }
    
    #active label { /*стиль отдельного переключателя*/
    border-radius: 10px; /*скругление углов*/
    display: inline-block; /*расположение в строку*/
    width: 15px;
    height: 15px;
    background: #bbb;
    }
    
    #active { /*расположение блока с переключателями*/
    margin: 23% 0 0;
    text-align: center;
    }
    
    #active label:hover { /*поведение чекбокса при наведении*/
    background: #76c8ff;
    border-color: #777 !important; /*выполнение в любом случае*/
    }
    
    /*цвет активного лейбла при активации чекбокса*/
    #switch1:checked ~ #active label:nth-child(1),
    #switch2:checked ~ #active label:nth-child(2),
    #switch3:checked ~ #active label:nth-child(3) {
    background: #18a3dd;
    border-color: #18a3dd !important;
    }
    
    #slides .image { /*анимация пролистывания изображений*/
    transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    }
    
    #controls label:hover { /*прозрачность стрелок при наведении*/
    opacity: 0.9;
    }
    
    #controls label { /*прозрачность стрелок при отводе курсора*/
    transition: opacity 0.2s ease-out;
    }
    
    .image article {
    display: flex;
    }
    
    .a1 button {
    background-color: #222E56;
    padding: 10px 24px 10px 24px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    margin: 38px 0 0 259.06px;
    cursor: pointer;
    }
    
    .a1 img {
    width: 367px;
    height: 79px;
    margin: 199px 0 0 151px;
    }
    
    .a2 button {
    background-color: #DFC070;
    padding: 10px 24px 10px 24px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    margin: 38px 0 0 259.06px;
    cursor: pointer;
    }
    
    .a2 img {
    width: 367px;
    height: 79px;
    margin: 199px 0 0 151px;
    }
}
@media (max-width: 1920px) {
  #desktop {
    display: none;
    }
    #slider input {
      display: none;
    }
    
    #slider { /*положение слайдера*/
    position: relative;
    text-align: center;
    margin: 0 auto;
    }
    
    #slides article { /*все изображения справа друг от доруга*/
    width: 20%;
    float: left;
    }
    
    #slides .image { /*устанавливает общий размер блока с изображениями*/
    width: 500%;
    line-height: 0;
    }
    
    #overflow { /*сркывает все, что находится за пределами этого блока*/
    width: 100%;
    overflow: hidden;
    }
    
    article img { /*размер изображений слайдера*/
    width: 60%;
    }
    
    #desktop:checked ~ #slider { /*размер всего слайдера*/
    max-width: 1440px; /*максимальнная длинна*/
    background-color: white;
    }
    
    /*настройка переключения и положения для левой стрелки*/
    /*если свич1-3 активны, то идет обращение к лейблу из блока с id контролс*/
    #switch1:checked ~ #controls label:nth-child(3),
    #switch2:checked ~ #controls label:nth-child(1),
    #switch3:checked ~ #controls label:nth-child(2) {
    background: url("../../../img/prev.png") no-repeat; /*заливка фона картинкой без повторений*/
    float: left;
    margin: 70px 0 0 20px; /*сдвиг влево*/
    display: block;
    height: 68px;
    width: 68px;
    }
    
    /*настройка переключения и положения для правой стрелки*/
    #switch1:checked ~ #controls label:nth-child(2),
    #switch2:checked ~ #controls label:nth-child(3),
    #switch3:checked ~ #controls label:nth-child(1) {
    background: url("../../../img/next.png") no-repeat; /*заливка фона картинкой без повторений*/
    float: right;
    margin-top: 70px;
    display: block;
    height: 68px;
    width: 68px;
    }
    
    .container3 label, a { /*при наведении на стрелки или переключатели - курсор изменится*/
    cursor: pointer;
    }
    
    .all input { /*скрывает стандартные инпуты (чекбоксы) на странице*/
    display: none;
    }
    
    /*позиция изображения при активации переключателя*/
    #switch1:checked ~ #slides .image {
    margin-left: 0;
    }
    
    #switch2:checked ~ #slides .image {
    margin-left: -100%;
    }
    
    #switch3:checked ~ #slides .image {
    margin-left: -200%;
    }
    
    #controls { /*положение блока всех управляющих элементов*/
    margin: -25% 0 0 0;
    width: 100%;
    height: 50px;
    }
    
    #active label { /*стиль отдельного переключателя*/
    border-radius: 10px; /*скругление углов*/
    display: inline-block; /*расположение в строку*/
    width: 15px;
    height: 15px;
    background: #bbb;
    }
    
    #active { /*расположение блока с переключателями*/
    margin: 23% 0 0;
    text-align: center;
    }
    
    #active label:hover { /*поведение чекбокса при наведении*/
    background: #76c8ff;
    border-color: #777 !important; /*выполнение в любом случае*/
    }
    
    /*цвет активного лейбла при активации чекбокса*/
    #switch1:checked ~ #active label:nth-child(1),
    #switch2:checked ~ #active label:nth-child(2),
    #switch3:checked ~ #active label:nth-child(3) {
    background: #18a3dd;
    border-color: #18a3dd !important;
    }
    
    #slides .image { /*анимация пролистывания изображений*/
    transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    }
    
    #controls label:hover { /*прозрачность стрелок при наведении*/
    opacity: 0.9;
    }
    
    #controls label { /*прозрачность стрелок при отводе курсора*/
    transition: opacity 0.2s ease-out;
    }
    
    .image article {
    display: flex;
    }
    
    .a1 button {
    background-color: #222E56;
    padding: 10px 24px 10px 24px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    margin: 38px 0 0 259.06px;
    cursor: pointer;
    }
    
    .a1 img {
    width: 367px;
    height: 79px;
    margin: 199px 0 0 151px;
    }
    
    .a2 button {
    background-color: #DFC070;
    padding: 10px 24px 10px 24px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    margin: 38px 0 0 259.06px;
    cursor: pointer;
    }
    
    .a2 img {
    width: 367px;
    height: 79px;
    margin: 199px 0 0 151px;
    }
}
@media (max-width: 1366px) {
  #desktop {
    display: none;
    }
    #slider input {
      display: none;
    }
    
    #slider { /*положение слайдера*/
    position: relative;
    text-align: center;
    margin: 0 auto;
    }
    
    #slides article { /*все изображения справа друг от доруга*/
    width: 20%;
    float: left;
    }
    
    #slides .image { /*устанавливает общий размер блока с изображениями*/
    width: 500%;
    line-height: 0;
    }
    
    #overflow { /*сркывает все, что находится за пределами этого блока*/
    width: 100%;
    overflow: hidden;
    }
    
    article img { /*размер изображений слайдера*/
    width: 60%;
    }
    
    #desktop:checked ~ #slider { /*размер всего слайдера*/
    max-width: 1440px; /*максимальнная длинна*/
    background-color: white;
    }
    
    /*настройка переключения и положения для левой стрелки*/
    /*если свич1-3 активны, то идет обращение к лейблу из блока с id контролс*/
    #switch1:checked ~ #controls label:nth-child(3),
    #switch2:checked ~ #controls label:nth-child(1),
    #switch3:checked ~ #controls label:nth-child(2) {
    background: url("../../../img/prev.png") no-repeat; /*заливка фона картинкой без повторений*/
    float: left;
    margin: 70px 0 0 20px; /*сдвиг влево*/
    display: block;
    height: 68px;
    width: 68px;
    }
    
    /*настройка переключения и положения для правой стрелки*/
    #switch1:checked ~ #controls label:nth-child(2),
    #switch2:checked ~ #controls label:nth-child(3),
    #switch3:checked ~ #controls label:nth-child(1) {
    background: url("../../../img/next.png") no-repeat; /*заливка фона картинкой без повторений*/
    float: right;
    margin-top: 70px;
    display: block;
    height: 68px;
    width: 68px;
    }
    
    .container3 label, a { /*при наведении на стрелки или переключатели - курсор изменится*/
    cursor: pointer;
    }
    
    .all input { /*скрывает стандартные инпуты (чекбоксы) на странице*/
    display: none;
    }
    
    /*позиция изображения при активации переключателя*/
    #switch1:checked ~ #slides .image {
    margin-left: 0;
    }
    
    #switch2:checked ~ #slides .image {
    margin-left: -100%;
    }
    
    #switch3:checked ~ #slides .image {
    margin-left: -200%;
    }
    
    #controls { /*положение блока всех управляющих элементов*/
    margin: -25% 0 0 0;
    width: 100%;
    height: 50px;
    }
    
    #active label { /*стиль отдельного переключателя*/
    border-radius: 10px; /*скругление углов*/
    display: inline-block; /*расположение в строку*/
    width: 15px;
    height: 15px;
    background: #bbb;
    }
    
    #active { /*расположение блока с переключателями*/
    margin: 23% 0 0;
    text-align: center;
    }
    
    #active label:hover { /*поведение чекбокса при наведении*/
    background: #76c8ff;
    border-color: #777 !important; /*выполнение в любом случае*/
    }
    
    /*цвет активного лейбла при активации чекбокса*/
    #switch1:checked ~ #active label:nth-child(1),
    #switch2:checked ~ #active label:nth-child(2),
    #switch3:checked ~ #active label:nth-child(3) {
    background: #18a3dd;
    border-color: #18a3dd !important;
    }
    
    #slides .image { /*анимация пролистывания изображений*/
    transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    }
    
    #controls label:hover { /*прозрачность стрелок при наведении*/
    opacity: 0.9;
    }
    
    #controls label { /*прозрачность стрелок при отводе курсора*/
    transition: opacity 0.2s ease-out;
    }
    
    .image article {
    display: flex;
    }
    
    .a1 button {
    background-color: #222E56;
    padding: 10px 24px 10px 24px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    margin: 38px 0 0 259.06px;
    cursor: pointer;
    }
    
    .a1 img {
    width: 367px;
    height: 79px;
    margin: 199px 0 0 151px;
    }
    
    .a2 button {
    background-color: #DFC070;
    padding: 10px 24px 10px 24px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    margin: 38px 0 0 259.06px;
    cursor: pointer;
    }
    
    .a2 img {
    width: 367px;
    height: 79px;
    margin: 199px 0 0 151px;
    }
}
@media (max-width: 1280px) {
  #desktop {
    display: none;
    }
    #slider input {
      display: none;
    }
    
    #slider { /*положение слайдера*/
    position: relative;
    text-align: center;
    margin: 0 auto;
    }
    
    #slides article { /*все изображения справа друг от доруга*/
    width: 20%;
    float: left;
    }
    
    #slides .image { /*устанавливает общий размер блока с изображениями*/
    width: 500%;
    line-height: 0;
    }
    
    #overflow { /*сркывает все, что находится за пределами этого блока*/
    width: 100%;
    overflow: hidden;
    }
    
    article img { /*размер изображений слайдера*/
    width: 60%;
    }
    
    #desktop:checked ~ #slider { /*размер всего слайдера*/
    max-width: 1440px; /*максимальнная длинна*/
    background-color: white;
    }
    
    /*настройка переключения и положения для левой стрелки*/
    /*если свич1-3 активны, то идет обращение к лейблу из блока с id контролс*/
    #switch1:checked ~ #controls label:nth-child(3),
    #switch2:checked ~ #controls label:nth-child(1),
    #switch3:checked ~ #controls label:nth-child(2) {
    background: url("../../../img/prev.png") no-repeat; /*заливка фона картинкой без повторений*/
    float: left;
    margin: 70px 0 0 20px; /*сдвиг влево*/
    display: block;
    height: 68px;
    width: 68px;
    }
    
    /*настройка переключения и положения для правой стрелки*/
    #switch1:checked ~ #controls label:nth-child(2),
    #switch2:checked ~ #controls label:nth-child(3),
    #switch3:checked ~ #controls label:nth-child(1) {
    background: url("../../../img/next.png") no-repeat; /*заливка фона картинкой без повторений*/
    float: right;
    margin-top: 70px;
    display: block;
    height: 68px;
    width: 68px;
    }
    
    .container3 label, a { /*при наведении на стрелки или переключатели - курсор изменится*/
    cursor: pointer;
    }
    
    .all input { /*скрывает стандартные инпуты (чекбоксы) на странице*/
    display: none;
    }
    
    /*позиция изображения при активации переключателя*/
    #switch1:checked ~ #slides .image {
    margin-left: 0;
    }
    
    #switch2:checked ~ #slides .image {
    margin-left: -100%;
    }
    
    #switch3:checked ~ #slides .image {
    margin-left: -200%;
    }
    
    #controls { /*положение блока всех управляющих элементов*/
    margin: -25% 0 0 0;
    width: 100%;
    height: 50px;
    }
    
    #active label { /*стиль отдельного переключателя*/
    border-radius: 10px; /*скругление углов*/
    display: inline-block; /*расположение в строку*/
    width: 15px;
    height: 15px;
    background: #bbb;
    }
    
    #active { /*расположение блока с переключателями*/
    margin: 23% 0 0;
    text-align: center;
    }
    
    #active label:hover { /*поведение чекбокса при наведении*/
    background: #76c8ff;
    border-color: #777 !important; /*выполнение в любом случае*/
    }
    
    /*цвет активного лейбла при активации чекбокса*/
    #switch1:checked ~ #active label:nth-child(1),
    #switch2:checked ~ #active label:nth-child(2),
    #switch3:checked ~ #active label:nth-child(3) {
    background: #18a3dd;
    border-color: #18a3dd !important;
    }
    
    #slides .image { /*анимация пролистывания изображений*/
    transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    }
    
    #controls label:hover { /*прозрачность стрелок при наведении*/
    opacity: 0.9;
    }
    
    #controls label { /*прозрачность стрелок при отводе курсора*/
    transition: opacity 0.2s ease-out;
    }
    
    .image article {
    display: flex;
    }
    
    .a1 button {
    background-color: #222E56;
    padding: 10px 24px 10px 24px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    margin: 38px 0 0 259.06px;
    cursor: pointer;
    }
    
    .a1 img {
    width: 367px;
    height: 79px;
    margin: 199px 0 0 151px;
    }
    
    .a2 button {
    background-color: #DFC070;
    padding: 10px 24px 10px 24px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    margin: 38px 0 0 259.06px;
    cursor: pointer;
    }
    
    .a2 img {
    width: 367px;
    height: 79px;
    margin: 199px 0 0 151px;
    }
}
@media (max-width: 1024px) {
  #desktop {
    display: none;
    }
    #slider input {
      display: none;
    }
    
    #slider { /*положение слайдера*/
    position: relative;
    text-align: center;
    margin: 0 auto;
    }
    
    #slides article { /*все изображения справа друг от доруга*/
    width: 20%;
    float: left;
    }
    
    #slides .image { /*устанавливает общий размер блока с изображениями*/
    width: 500%;
    line-height: 0;
    }
    
    #overflow { /*сркывает все, что находится за пределами этого блока*/
    width: 100%;
    overflow: hidden;
    }
    
    article img { /*размер изображений слайдера*/
    width: 60%;
    }
    
    #desktop:checked ~ #slider { /*размер всего слайдера*/
    max-width: 1440px; /*максимальнная длинна*/
    background-color: white;
    }
    
    /*настройка переключения и положения для левой стрелки*/
    /*если свич1-3 активны, то идет обращение к лейблу из блока с id контролс*/
    #switch1:checked ~ #controls label:nth-child(3),
    #switch2:checked ~ #controls label:nth-child(1),
    #switch3:checked ~ #controls label:nth-child(2) {
    background: url("../../../img/prev.png") no-repeat; /*заливка фона картинкой без повторений*/
    float: left;
    margin: 70px 0 0 20px; /*сдвиг влево*/
    display: block;
    height: 68px;
    width: 68px;
    }
    
    /*настройка переключения и положения для правой стрелки*/
    #switch1:checked ~ #controls label:nth-child(2),
    #switch2:checked ~ #controls label:nth-child(3),
    #switch3:checked ~ #controls label:nth-child(1) {
    background: url("../../../img/next.png") no-repeat; /*заливка фона картинкой без повторений*/
    float: right;
    margin-top: 70px;
    display: block;
    height: 68px;
    width: 68px;
    }
    
    .container3 label, a { /*при наведении на стрелки или переключатели - курсор изменится*/
    cursor: pointer;
    }
    
    .all input { /*скрывает стандартные инпуты (чекбоксы) на странице*/
    display: none;
    }
    
    /*позиция изображения при активации переключателя*/
    #switch1:checked ~ #slides .image {
    margin-left: 0;
    }
    
    #switch2:checked ~ #slides .image {
    margin-left: -100%;
    }
    
    #switch3:checked ~ #slides .image {
    margin-left: -200%;
    }
    
    #controls { /*положение блока всех управляющих элементов*/
    margin: -25% 0 0 0;
    width: 100%;
    height: 50px;
    }
    
    #active label { /*стиль отдельного переключателя*/
    border-radius: 10px; /*скругление углов*/
    display: inline-block; /*расположение в строку*/
    width: 15px;
    height: 15px;
    background: #bbb;
    }
    
    #active { /*расположение блока с переключателями*/
    margin: 23% 0 0;
    text-align: center;
    }
    
    #active label:hover { /*поведение чекбокса при наведении*/
    background: #76c8ff;
    border-color: #777 !important; /*выполнение в любом случае*/
    }
    
    /*цвет активного лейбла при активации чекбокса*/
    #switch1:checked ~ #active label:nth-child(1),
    #switch2:checked ~ #active label:nth-child(2),
    #switch3:checked ~ #active label:nth-child(3) {
    background: #18a3dd;
    border-color: #18a3dd !important;
    }
    
    #slides .image { /*анимация пролистывания изображений*/
    transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    }
    
    #controls label:hover { /*прозрачность стрелок при наведении*/
    opacity: 0.9;
    }
    
    #controls label { /*прозрачность стрелок при отводе курсора*/
    transition: opacity 0.2s ease-out;
    }
    
    .image article {
    display: flex;
    }
    
    .a1 button {
    background-color: #222E56;
    padding: 10px 24px 10px 24px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    margin: 38px 0 0 259.06px;
    cursor: pointer;
    }
    
    .a1 img {
    width: 367px;
    height: 79px;
    margin: 199px 0 0 151px;
    }
    
    .a2 button {
    background-color: #DFC070;
    padding: 10px 24px 10px 24px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    margin: 38px 0 0 259.06px;
    cursor: pointer;
    }
    
    .a2 img {
    width: 367px;
    height: 79px;
    margin: 199px 0 0 151px;
    }
}
@media (max-width: 720px) {
  #desktop {
    display: none;
    }
    #slider input {
      display: none;
    }
    
    #slider { /*положение слайдера*/
    position: relative;
    text-align: center;
    margin: 0 auto;
    display: none;
    }
    
    #slides article { /*все изображения справа друг от доруга*/
    width: 20%;
    float: left;
    }
    
    #slides .image { /*устанавливает общий размер блока с изображениями*/
    width: 500%;
    line-height: 0;
    }
    
    #overflow { /*сркывает все, что находится за пределами этого блока*/
    width: 100%;
    overflow: hidden;
    }
    
    article img { /*размер изображений слайдера*/
    width: 60%;
    }
    
    #desktop:checked ~ #slider { /*размер всего слайдера*/
    max-width: 414px; /*максимальнная длинна*/
    background-color: white;
    }
    
    /*настройка переключения и положения для левой стрелки*/
    /*если свич1-3 активны, то идет обращение к лейблу из блока с id контролс*/
    #switch1:checked ~ #controls label:nth-child(3),
    #switch2:checked ~ #controls label:nth-child(1),
    #switch3:checked ~ #controls label:nth-child(2) {
    background: url("../../../img/prev.png") no-repeat; /*заливка фона картинкой без повторений*/
    float: left;
    margin: 70px 0 0 20px; /*сдвиг влево*/
    display: block;
    height: 68px;
    width: 68px;
    }
    
    /*настройка переключения и положения для правой стрелки*/
    #switch1:checked ~ #controls label:nth-child(2),
    #switch2:checked ~ #controls label:nth-child(3),
    #switch3:checked ~ #controls label:nth-child(1) {
    background: url("../../../img/next.png") no-repeat; /*заливка фона картинкой без повторений*/
    float: right;
    margin-top: 70px;
    display: block;
    height: 68px;
    width: 68px;
    }
    
    .container3 label, a { /*при наведении на стрелки или переключатели - курсор изменится*/
    cursor: pointer;
    }
    
    .all input { /*скрывает стандартные инпуты (чекбоксы) на странице*/
    display: none;
    }
    
    /*позиция изображения при активации переключателя*/
    #switch1:checked ~ #slides .image {
    margin-left: 0;
    }
    
    #switch2:checked ~ #slides .image {
    margin-left: -100%;
    }
    
    #switch3:checked ~ #slides .image {
    margin-left: -200%;
    }
    
    #controls { /*положение блока всех управляющих элементов*/
    margin: -25% 0 0 0;
    width: 100%;
    height: 50px;
    }
    
    #active label { /*стиль отдельного переключателя*/
    border-radius: 10px; /*скругление углов*/
    display: inline-block; /*расположение в строку*/
    width: 15px;
    height: 15px;
    background: #bbb;
    }
    
    #active { /*расположение блока с переключателями*/
    margin: 23% 0 0;
    text-align: center;
    }
    
    #active label:hover { /*поведение чекбокса при наведении*/
    background: #76c8ff;
    border-color: #777 !important; /*выполнение в любом случае*/
    }
    
    /*цвет активного лейбла при активации чекбокса*/
    #switch1:checked ~ #active label:nth-child(1),
    #switch2:checked ~ #active label:nth-child(2),
    #switch3:checked ~ #active label:nth-child(3) {
    background: #18a3dd;
    border-color: #18a3dd !important;
    }
    
    #slides .image { /*анимация пролистывания изображений*/
    transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
    }
    
    #controls label:hover { /*прозрачность стрелок при наведении*/
    opacity: 0.9;
    }
    
    #controls label { /*прозрачность стрелок при отводе курсора*/
    transition: opacity 0.2s ease-out;
    }
    
    .image article {
    display: flex;
    }
    
    .a1 button {
    background-color: #222E56;
    padding: 10px 24px 10px 24px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    margin: 38px 0 0 259.06px;
    cursor: pointer;
    }
    
    .a1 img {
    width: 367px;
    height: 79px;
    margin: 199px 0 0 151px;
    }
    .a1 .wp_logo{
      display: none;
    }
    
    .a2 button {
    background-color: #DFC070;
    padding: 10px 24px 10px 24px;
    color: #fff;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    margin: 38px 0 0 259.06px;
    cursor: pointer;
    }
    
    .a2 img {
    width: 367px;
    height: 79px;
    margin: 199px 0 0 151px;
    }

    .a2 .wp_logo{
      display: none;
    }
}