@import "../../css/colors";
@media (max-width: 4320px) {
    .header_wrapper{
        width: 100%;
        height: 80px;
        .header{
            width: 100%;
            background-color: $primary_color2;
            height: 80px;
            position: fixed;
            z-index: 1000;
            .container{
                max-width: 1440px;
                margin: 0 auto;
                display: flex;
                .wallpaper_logo{
                    width: auto;
                    height: 40px;
                    margin: 20px 40px 0 0;
                }
                form.search {
                    display: flex; /* Используем flexbox для выравнивания элементов в строку */
                }
                .search{
                    margin: 20px 0 0 130.5px;
                    .search_tab{
                        padding: 11px 0 11px 16px;
                        color: $neutral_color6;
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        width: 258px;
                        margin-right: 0;
                        border-radius: 4px 0 0 4px;
                    }
                    .category_select{
                        width: 139px;
                        border-left: solid 1px $neutral_color5;
                        padding: 0 0 0 13px;
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                    }
                    .search_submit_button{
                        width: 47px;
                        height: 40px;
                        background-color: $primary_color1;
                        border-radius: 0 4px 4px 0;
                    }
                }
                .nav{
                    display: flex;
                    .navigation{
                        display: flex;
                        align-items: center;
                        .navigation_menu{
                            display: flex;
                            margin: 11px 42px 0 0;
                            .nav_link{
                                margin: 0 14px 0 14px;
                                color: $primary_color1;
                                font-size: 14px;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                &:hover{
                                    cursor: pointer;
                                    color: $secondary_color2;
                                    transition: 0.2s;
                                }
                            }
                            button{
                                background-color: Transparent;
                                background-repeat:no-repeat;
                                border: none;
                                cursor: pointer;
                                overflow: hidden;
                                outline: none;
                                padding: 0;
                                border-radius: 0;
                                margin: -2px 14px 0 14px;
                                color: $primary_color1;
                                font-size: 14px;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                &:hover{
                                    cursor: pointer;
                                    color: $secondary_color2;
                                    transition: 0.2s;
                                }
                            }
                        }
                        .shoping_cart{
                            margin: 6px 42px 0 0;
                            background-color: rgba(255, 255, 255, 0);
                            border-radius: 4px;
                            padding: 2px;
                            display: flex;
                            height: 26px;
                            &:hover{
                                background-color: $neutral_color6;
                                transition: 0.3s;
                            }
                            .add_shopping_cart_img{
                                margin: 0 4px 0 0;
                                width: 24px;
                                height: 24px;
                            }
                            .cart_text{
                                color: $secondary_color2;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                font-size: 14px;
                                margin: 3px 0 3px 0;
                            }
                        }
                        .language_bar{
                            border-radius: 4px 0 0 4px;
                        }
                        .language_bar_flag{
                            width: 48px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1920px) {
    .header_wrapper{
        width: 100%;
        height: 80px;
        .header{
            width: 100%;
            background-color: $primary_color2;
            height: 80px;
            position: fixed;
            z-index: 1000;
            .container{
                max-width: 1440px;
                margin: 0 auto;
                display: flex;
                .wallpaper_logo{
                    width: auto;
                    height: 40px;
                }
                form.search {
                    display: flex;
                }
                .search{
                    margin: 20px 0 0 130.5px;
                    .search_tab{
                        padding: 11px 0 11px 16px;
                        color: $neutral_color6;
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        width: 258px;
                        margin-right: 0;
                        border-radius: 4px 0 0 4px;
                    }
                    .category_select{
                        width: 139px;
                        border-left: solid 1px $neutral_color5;
                        padding: 0 0 0 13px;
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                    }
                    .search_submit_button{
                        width: 47px;
                        height: 40px;
                        background-color: $primary_color1;
                        border-radius: 0 4px 4px 0;
                    }
                }
                .nav{
                    display: flex;
                    .navigation{
                        .navigation_menu{
                            .nav_link{
                                color: $primary_color1;
                                font-size: 14px;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                &:hover{
                                    cursor: pointer;
                                    color: $secondary_color2;
                                    transition: 0.2s;
                                }
                            }
                            button{
                                background-color: Transparent;
                                background-repeat:no-repeat;
                                border: none;
                                cursor: pointer;
                                overflow: hidden;
                                outline: none;
                                padding: 0;
                                border-radius: 0;
                                margin: -2px 14px 0 14px;
                                color: $primary_color1;
                                font-size: 14px;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                &:hover{
                                    cursor: pointer;
                                    color: $secondary_color2;
                                    transition: 0.2s;
                                }
                            }
                        }
                        .shoping_cart{
                            margin: 6px 42px 0 0;
                            background-color: rgba(255, 255, 255, 0);
                            border-radius: 4px;
                            padding: 2px;
                            display: flex;
                            height: 26px;
                            &:hover{
                                background-color: $neutral_color6;
                                transition: 0.3s;
                            }
                            .add_shopping_cart_img{
                                margin: 0 4px 0 0;
                                width: 24px;
                                height: 24px;
                            }
                            .cart_text{
                                color: $secondary_color2;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                font-size: 14px;
                                margin: 3px 0 3px 0;
                            }
                        }
                        .language_bar{
                            border-radius: 4px 0 0 4px;
                        }
                        .language_bar_flag{
                            width: 48px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1366px) {
    .header_wrapper{
        width: 100%;
        height: 70px;
        .header{
            width: 100%;
            background-color: $primary_color2;
            height: 70px;
            position: fixed;
            z-index: 1000;
            .container{
                max-width: 1366px;
                margin: 0 auto;
                display: flex;
                .wallpaper_logo{
                    display: flex;
                    width: auto;
                    height: 30px;
                    margin: 20px 40px 0 20px;
                }
                form.search {
                    display: flex; /* Используем flexbox для выравнивания элементов в строку */
                }
                .search{
                    margin: 20px 0 0 130.5px;
                    .search_tab{
                        padding: 11px 0 11px 16px;
                        color: $neutral_color6;
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        width: 258px;
                        margin-right: 0;
                        border-radius: 4px 0 0 4px;
                    }
                    .category_select{
                        width: 139px;
                        border-left: solid 1px $neutral_color5;
                        padding: 0 0 0 13px;
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                    }
                    .search_submit_button{
                        width: 47px;
                        height: 40px;
                        background-color: $primary_color1;
                        border-radius: 0 4px 4px 0;
                    }
                }
                .nav{
                    display: flex;
                    .navigation{
                        .navigation_menu{
                            display: flex;
                            margin: 18px 42px 0 0;
                            .nav_link{
                                margin: 0 14px 0 14px;
                                color: $primary_color1;
                                font-size: 12px;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                &:hover{
                                    cursor: pointer;
                                    color: $secondary_color2;
                                    transition: 0.2s;
                                }
                            }
                            button{
                                background-color: Transparent;
                                background-repeat:no-repeat;
                                border: none;
                                cursor: pointer;
                                overflow: hidden;
                                outline: none;
                                padding: 0;
                                border-radius: 0;
                                margin: -2px 14px 0 14px;
                                color: $primary_color1;
                                font-size: 12px;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                &:hover{
                                    cursor: pointer;
                                    color: $secondary_color2;
                                    transition: 0.2s;
                                }
                            }
                        }
                        .shoping_cart{
                            margin: 6px 42px 0 0;
                            background-color: rgba(255, 255, 255, 0);
                            border-radius: 4px;
                            padding: 2px;
                            display: flex;
                            height: 26px;
                            &:hover{
                                background-color: $neutral_color6;
                                transition: 0.3s;
                            }
                            .add_shopping_cart_img{
                                margin: 0 4px 0 0;
                                width: 24px;
                                height: 24px;
                            }
                            .cart_text{
                                color: $secondary_color2;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                font-size: 14px;
                                margin: 3px 0 3px 0;
                            }
                        }
                        .language_bar{
                            border-radius: 4px 0 0 4px;
                        }
                        .language_bar_flag{
                            width: 48px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1280px) {
    .header_wrapper{
        width: 100%;
        height: 70px;
        .header{
            width: 100%;
            background-color: $primary_color2;
            height: 70px;
            position: fixed;
            z-index: 1000;
            .container{
                max-width: 1280px;
                margin: 0 20px;
                display: flex;
                .wallpaper_logo{
                    width: auto;
                    height: 30px;
                    align-items: center;
                    justify-content: center;
                }
                form.search {
                    display: flex; /* Используем flexbox для выравнивания элементов в строку */
                }
                .search{
                    margin: 20px 0 0 130.5px;
                    .search_tab{
                        padding: 11px 0 11px 16px;
                        color: $neutral_color6;
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        width: 258px;
                        margin-right: 0;
                        border-radius: 4px 0 0 4px;
                    }
                    .category_select{
                        width: 139px;
                        border-left: solid 1px $neutral_color5;
                        padding: 0 0 0 13px;
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                    }
                    .search_submit_button{
                        width: 47px;
                        height: 40px;
                        background-color: $primary_color1;
                        border-radius: 0 4px 4px 0;
                    }
                }
                .nav{
                    display: flex;
                    .navigation{
                        .navigation_menu{
                            display: flex;
                            margin: 18px 42px 0 0;
                            .nav_link{
                                margin: 0 14px 0 14px;
                                color: $primary_color1;
                                font-size: 12px;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                &:hover{
                                    cursor: pointer;
                                    color: $secondary_color2;
                                    transition: 0.2s;
                                }
                            }
                            button{
                                background-color: Transparent;
                                background-repeat:no-repeat;
                                border: none;
                                cursor: pointer;
                                overflow: hidden;
                                outline: none;
                                padding: 0;
                                border-radius: 0;
                                margin: -2px 14px 0 14px;
                                color: $primary_color1;
                                font-size: 12px;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                &:hover{
                                    cursor: pointer;
                                    color: $secondary_color2;
                                    transition: 0.2s;
                                }
                            }
                        }
                        .shoping_cart{
                            margin: 6px 42px 0 0;
                            background-color: rgba(255, 255, 255, 0);
                            border-radius: 4px;
                            padding: 2px;
                            display: flex;
                            height: 26px;
                            &:hover{
                                background-color: $neutral_color6;
                                transition: 0.3s;
                            }
                            .add_shopping_cart_img{
                                margin: 0 4px 0 0;
                                width: 24px;
                                height: 24px;
                            }
                            .cart_text{
                                color: $secondary_color2;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                font-size: 14px;
                                margin: 3px 0 3px 0;
                            }
                        }
                        .language_bar{
                            border-radius: 4px 0 0 4px;
                        }
                        .language_bar_flag{
                            width: 48px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    .header_wrapper{
        width: 100%;
        height: 80px;
        .header{
            width: 100%;
            background-color: $primary_color2;
            height: 80px;
            position: fixed;
            z-index: 1000;
            .container{
                max-width: 1024px;
                margin: 0 auto;
                display: flex;
                .wallpaper_logo{
                    width: auto;
                    height: 35px;
                    margin: 25px 0 0 25px;
                }
                form.search {
                    display: flex; /* Используем flexbox для выравнивания элементов в строку */
                }
                .search{
                    margin: 20px 0 0 130.5px;
                    .search_tab{
                        padding: 11px 0 11px 16px;
                        color: $neutral_color6;
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        width: 258px;
                        margin-right: 0;
                        border-radius: 4px 0 0 4px;
                    }
                    .category_select{
                        width: 139px;
                        border-left: solid 1px $neutral_color5;
                        padding: 0 0 0 13px;
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                    }
                    .search_submit_button{
                        width: 47px;
                        height: 40px;
                        background-color: $primary_color1;
                        border-radius: 0 4px 4px 0;
                    }
                }
                .nav{
                    .navigation{
                        display: flex;
                        .navigation_menu{
                            display: flex;
                            margin: 19px 42px 0 20px;
                            .nav_link{
                                margin: 0 14px 0 14px;
                                color: $primary_color1;
                                font-size: 14px;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                &:hover{
                                    cursor: pointer;
                                    color: $secondary_color2;
                                    transition: 0.2s;
                                }
                            }
                            button{
                                background-color: Transparent;
                                background-repeat:no-repeat;
                                border: none;
                                cursor: pointer;
                                overflow: hidden;
                                outline: none;
                                padding: 0;
                                border-radius: 0;
                                margin: -2px 14px 0 14px;
                                color: $primary_color1;
                                font-size: 14px;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                &:hover{
                                    cursor: pointer;
                                    color: $secondary_color2;
                                    transition: 0.2s;
                                }
                            }
                        }
                        .shoping_cart{
                            margin: 6px 42px 0 0;
                            background-color: rgba(255, 255, 255, 0);
                            border-radius: 4px;
                            padding: 2px;
                            display: flex;
                            height: 26px;
                            &:hover{
                                background-color: $neutral_color6;
                                transition: 0.3s;
                            }
                            .add_shopping_cart_img{
                                margin: 0 4px 0 0;
                                width: 24px;
                                height: 24px;
                            }
                            .cart_text{
                                color: $secondary_color2;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                font-size: 14px;
                                margin: 3px 0 3px 0;
                            }
                        }
                        .language_bar{
                            border-radius: 4px 0 0 4px;
                        }
                        .language_bar_flag{
                            width: 48px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 720px) {
    .header_wrapper{
        width: 100%;
        height: 80px;
        .header{
            width: 100%;
            background-color: $primary_color2;
            height: 80px;
            position: fixed;
            z-index: 1000;
            .container{
                max-width: 720px;
                margin: 0 auto;
                display: flex;
                .wallpaper_logo{
                    width: auto;
                    height: 35px;
                    margin: 25px 0 0 25px;
                }
                form.search {
                    display: flex; /* Используем flexbox для выравнивания элементов в строку */
                }
                .search{
                    margin: 20px 0 0 130.5px;
                    .search_tab{
                        padding: 11px 0 11px 16px;
                        color: $neutral_color6;
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                        width: 258px;
                        margin-right: 0;
                        border-radius: 4px 0 0 4px;
                    }
                    .category_select{
                        width: 139px;
                        border-left: solid 1px $neutral_color5;
                        padding: 0 0 0 13px;
                        font-size: 14px;
                        font-family: 'Roboto', sans-serif;
                        font-weight: 400;
                    }
                    .search_submit_button{
                        width: 47px;
                        height: 40px;
                        background-color: $primary_color1;
                        border-radius: 0 4px 4px 0;
                    }
                }
                .nav{
                    .navigation{
                        display: flex;
                        .navigation_menu{
                            display: flex;
                            align-items: center;
                            .nav_link{
                                margin: 0 3px 0 3px;
                                color: $primary_color1;
                                font-size: 14px;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                &:hover{
                                    cursor: pointer;
                                    color: $secondary_color2;
                                    transition: 0.2s;
                                }
                            }
                            button{
                                background-color: Transparent;
                                background-repeat:no-repeat;
                                border: none;
                                cursor: pointer;
                                overflow: hidden;
                                outline: none;
                                padding: 0;
                                border-radius: 0;
                                margin: -2px 14px 0 14px;
                                color: $primary_color1;
                                font-size: 14px;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                &:hover{
                                    cursor: pointer;
                                    color: $secondary_color2;
                                    transition: 0.2s;
                                }
                            }
                        }
                        .shoping_cart{
                            margin: 6px 42px 0 0;
                            background-color: rgba(255, 255, 255, 0);
                            border-radius: 4px;
                            padding: 2px;
                            display: flex;
                            height: 26px;
                            &:hover{
                                background-color: $neutral_color6;
                                transition: 0.3s;
                            }
                            .add_shopping_cart_img{
                                margin: 0 4px 0 0;
                                width: 24px;
                                height: 24px;
                            }
                            .cart_text{
                                color: $secondary_color2;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                font-size: 14px;
                                margin: 3px 0 3px 0;
                            }
                        }
                        .language_bar{
                            border-radius: 4px 0 0 4px;
                        }
                        .language_bar_flag{
                            width: 48px;
                            height: 40px;
                        }
                    }
                }
            }
        }
    }
}