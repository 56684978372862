@import "../../css/colors";
@media (max-width: 4320px) {
    .container{
        .admin_panel{
            display: flex;
            max-height: 90vh;
            .admin_panel_aside{
                border: solid 1px $secondary_color1;
                display: flex;
                flex-direction: column;
                background-color: #777;
                h1{
                    padding: 30px;
                    font-size: 20px;
                    border-bottom: solid 1px $secondary_color1;
                    color: $secondary_color1;
                    &:hover{
                        background-color: #5e5e5e;
                    }
                }
                h2{
                    font-size: 22px;
                    margin-top: 240px;
                    text-align: center;
                    position: relative;
                }
                ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li{
                        color: $secondary_color2;
                        padding: 20px;
                        height: auto;
                        font-size: 16px;
                        text-align: center;
                        cursor: pointer;
                        &:hover{
                            background-color: #383838;
                        }
                    }
                }
            }
            .admin_panel_window{
                background-color: #9e9e9e;
                width: 1200px;
                .wallpaper_market_logo{
                    margin: 322.5px auto;
                    width: 250px;
                    display: block;
                    opacity: 70%;
                }
            }
        }
    }
}
@media (max-width: 1920px) {
    .container{
        .admin_panel{
            display: flex;
            max-height: 90vh;
            .admin_panel_aside{
                border: solid 1px $secondary_color1;
                display: flex;
                flex-direction: column;
                background-color: #777;
                h1{
                    padding: 30px;
                    font-size: 20px;
                    border-bottom: solid 1px $secondary_color1;
                    color: $secondary_color1;
                    &:hover{
                        background-color: #5e5e5e;
                    }
                }
                h2{
                    font-size: 22px;
                    margin-top: 240px;
                    text-align: center;
                    position: relative;
                }
                ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li{
                        color: $secondary_color2;
                        padding: 20px;
                        height: auto;
                        font-size: 16px;
                        text-align: center;
                        cursor: pointer;
                        &:hover{
                            background-color: #383838;
                        }
                    }
                }
            }
            .admin_panel_window{
                background-color: #9e9e9e;
                width: 1200px;
                .wallpaper_market_logo{
                    margin: 322.5px auto;
                    width: 250px;
                    display: block;
                    opacity: 70%;
                }
            }
        }
    }
}
@media (max-width: 1366px) {
    .container{
        .admin_panel{
            display: flex;
            max-height: 90vh;
            .admin_panel_aside{
                border: solid 1px $secondary_color1;
                display: flex;
                flex-direction: column;
                background-color: #777;
                h1{
                    padding: 30px;
                    font-size: 20px;
                    border-bottom: solid 1px $secondary_color1;
                    color: $secondary_color1;
                    &:hover{
                        background-color: #5e5e5e;
                    }
                }
                h2{
                    font-size: 22px;
                    margin-top: 240px;
                    text-align: center;
                    position: relative;
                }
                ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li{
                        color: $secondary_color2;
                        padding: 20px;
                        height: auto;
                        font-size: 16px;
                        text-align: center;
                        cursor: pointer;
                        &:hover{
                            background-color: #383838;
                        }
                    }
                }
            }
            .admin_panel_window{
                background-color: #9e9e9e;
                width: 1100px;
                .wallpaper_market_logo{
                    margin: 322.5px auto;
                    width: 250px;
                    display: block;
                    opacity: 70%;
                }
            }
        }
    }
}
@media (max-width: 1280px) {
    .container{
        .admin_panel{
            display: flex;
            max-height: 90vh;
            .admin_panel_aside{
                border: solid 1px $secondary_color1;
                display: flex;
                flex-direction: column;
                background-color: #777;
                h1{
                    padding: 30px;
                    font-size: 20px;
                    border-bottom: solid 1px $secondary_color1;
                    color: $secondary_color1;
                    &:hover{
                        background-color: #5e5e5e;
                    }
                }
                h2{
                    font-size: 22px;
                    margin-top: 240px;
                    text-align: center;
                    position: relative;
                }
                ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li{
                        color: $secondary_color2;
                        padding: 20px;
                        height: auto;
                        font-size: 16px;
                        text-align: center;
                        cursor: pointer;
                        &:hover{
                            background-color: #383838;
                        }
                    }
                }
            }
            .admin_panel_window{
                background-color: #9e9e9e;
                width: 1000px;
                .wallpaper_market_logo{
                    margin: 322.5px auto;
                    width: 250px;
                    display: block;
                    opacity: 70%;
                }
            }
        }
    }
}
@media (max-width: 1024px) {
    .container{
        .admin_panel{
            display: flex;
            max-height: 90vh;
            .admin_panel_aside{
                border: solid 1px $secondary_color1;
                display: flex;
                flex-direction: column;
                background-color: #777;
                h1{
                    padding: 30px;
                    font-size: 20px;
                    border-bottom: solid 1px $secondary_color1;
                    color: $secondary_color1;
                    &:hover{
                        background-color: #5e5e5e;
                    }
                }
                h2{
                    font-size: 22px;
                    margin-top: 240px;
                    text-align: center;
                    position: relative;
                }
                ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li{
                        color: $secondary_color2;
                        padding: 20px;
                        height: auto;
                        font-size: 16px;
                        text-align: center;
                        cursor: pointer;
                        &:hover{
                            background-color: #383838;
                        }
                    }
                }
            }
            .admin_panel_window{
                background-color: #9e9e9e;
                width: 800px;
                .wallpaper_market_logo{
                    margin: 322.5px auto;
                    width: 250px;
                    display: block;
                    opacity: 70%;
                }
            }
        }
    }
}
@media (max-width: 720px) {
    .container{
        .admin_panel{
            display: flex;
            max-height: 90vh;
            .admin_panel_aside{
                border: solid 1px $secondary_color1;
                display: none;
                flex-direction: column;
                background-color: #777;
                h1{
                    padding: 30px;
                    font-size: 20px;
                    border-bottom: solid 1px $secondary_color1;
                    color: $secondary_color1;
                    &:hover{
                        background-color: #5e5e5e;
                    }
                }
                h2{
                    font-size: 22px;
                    margin-top: 240px;
                    text-align: center;
                    position: relative;
                }
                ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li{
                        color: $secondary_color2;
                        padding: 20px;
                        height: auto;
                        font-size: 16px;
                        text-align: center;
                        cursor: pointer;
                        &:hover{
                            background-color: #383838;
                        }
                    }
                }
            }
            .admin_panel_window{
                background-color: #9e9e9e;
                width: 414px;
                .wallpaper_market_logo{
                    margin: 322.5px auto;
                    width: 250px;
                    display: block;
                    opacity: 70%;
                }
            }
        }
    }
}