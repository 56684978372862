@import "../../css/colors";
.button{
    button{
        background-color: $primary_color1;
        padding: 0.5vh 1.2vh 0.5vh 1.2vh;
        color: $secondary_color2;
        font-size: 1.4vh;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        border-radius: 0.4vh;
        cursor: pointer;
        margin: 0 1vh 1vh 1vh;
    }
}