@import "../../css/colors";

.footer{
    width: 100%;
    background-color: $primary_color1;
    height: 44.6vh;
    .container2{
        // max-width: 1440px;
        margin: 0 auto;
        padding: 7.4vh 41vh 0 41vh;
        .footer_container{
            display: flex;
            justify-content: space-between;
            .footer_container_1{
                .footer_container_1_img{
                    width: 25vh;
                    height: auto;
                    margin: 0 0 4.3vh 0;
                }
                .footer_container_1_2{
                    display: flex;
                    justify-content: space-between;
                }
                .footer_container_1_3{
                    margin: 2vh 0 0 0;
                    a{
                        color: $secondary_color2;
                    }
                }
            }
            .footer_container_2{
                display: flex;
                .products{
                    .footer_container_2_text{
                        color: $primary_color2;
                        font-family: 'DM Sans', sans-serif;
                        font-size: 2vh;
                        font-weight: 700;
                        margin: 0 0 2.2vh 0;
                    }
                    .footer_container_2_links{
                        color: $secondary_color2;
                        font-size: 1.8vh;
                        font-family: 'DM Sans', sans-serif;
                        display: block;
                        margin: 0 0 1.8vh 0;
                    }
                }
                .contact{
                    margin: 0 0 0 9.3vh;
                    .footer_container_2_text{
                        color: $primary_color2;
                        font-family: 'DM Sans', sans-serif;
                        font-size: 2vh;
                        font-weight: 700;
                        margin: 0 0 2.2vh 0;
                    }
                    .footer_container_2_links{
                        color: $secondary_color2;
                        font-size: 2vh;
                        font-family: 'DM Sans', sans-serif;
                        display: block;
                        margin: 0 0 1.8vh 0;
                    }
                }
            }
            .copyright{
                text-align: center;
                margin: 3.2vh 0 0 0;
                .colored_copyright{
                    color: $primary_color2;
                }
                .colored_copyright2{
                    color: #E3E3E3;
                }
                .colored_copyright3{
                    color: #8B8B8B;
                }
            }
        }
        .hr{
            margin: 11vh 0 0 0;
            border: 0.1vh solid #D4D2E3;
        }
        .copyright {
            text-align: center;
            margin: 3.2vh 0 0 0;
            .colored_copyright {
                color: #DFC070;
              }
              
              .colored_copyright2 {
                color: #E3E3E3;
              }
              
              .colored_copyright3 {
                color: #8B8B8B;
              }
        }
        .createdBy{
            text-align: center;
            margin: 3.2vh 0 0 0;
            color: $neutral_color4;
            a{
                color: $secondary_color2;
            }
        }
    }
}