@import "../../../css/colors";

body.active-modal {
    overflow-y: hidden;
}

.modal {
    width: 100vw;
    height: 100vh;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1;

    .overlay {
        z-index: -1;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        background-color: #ffffff48;
    }

    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 1.4;
        background: $primary_color3;
        padding: 20px;
        border-radius: 3px;
        display: flex;
        flex-direction: row; /* Делаем категории горизонтальными колонками */

        .category_ul {
            list-style: none;
            padding: 0 20px;
            margin: 0;
            max-height: 60vh; /* Ограничиваем высоту списка */
            overflow-y: auto; /* Прокрутка для длинных списков */

            .category_li {
                margin: 10px 0;
                color: $primary_color1;
                cursor: pointer;
                font-size: 1.2rem;

                &:hover {
                    color: $primary_color2;
                }
            }
        }

        .subcategory_ul {
            list-style: none;
            padding: 0 20px;
            margin: 0;
            max-height: 60vh; /* Ограничиваем высоту списка */
            overflow-y: auto;

            .subcategory_li {
                margin: 10px 0;
                color: $primary_color1;
                cursor: pointer;
                font-size: 1rem;

                &:hover {
                    color: $primary_color2;
                }
            }
        }

        .modal-content-hr {
            border: 0.1vh solid $primary_color1;
            margin: 10px 0;
        }

        .subcategory {
            width: auto; /* Динамическая ширина подкатегорий */
        }

        .close-modal {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            font-size: 1.5rem;
            color: $primary_color1;

            &:hover {
                color: $primary_color2;
            }
        }
    }
}
