.admin_panel_window{
    max-height: 100%;
    overflow-y: auto;
    .admin_panel_edit_cat_form{
        input, .category_select, label{
            border-radius: 5px;
            width: 200px;
            display: flex;
            margin: 1vh;
            padding: 3px;
        }

        .categories{
            display: flex;
            flex-direction: column;
            max-height: 50vh;
            overflow-y: auto;
            background-color: #fff;
            padding: 1vh;
            margin: 1vh;

            span{
                margin: 0.2vh 0;
                cursor: pointer;

                &:hover{
                    color: #555;
                }
            }
        }
        .admin_panel_edit_cat_button{
            padding: 5px;
            margin: 0 1vh;
        }
    }
}