@import "../../css/colors";
.adminCompWrapper{
    display: flex;
    width: 40vh;
    background-color: $primary_color2;
    padding: 0.5vh;
    height: 6vh;
    z-index: 1000;
    border-radius: 0.3vh;
    margin: 0 0 1.5vh 0;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    button{
        margin: 1vh auto;
        padding: 0.5vh;
    }
}